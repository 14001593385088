import React, {useContext} from 'react';
import CurrentPageContext from '@/components/context/CurrentPageData';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {pickImageStyle} from "@/utils/transforms";
import parse from 'html-react-parser';
import {useRouter} from "next/router";
import Hidden from '@material-ui/core/Hidden';
import Image from 'next/image';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      textAlign: 'center',
      marginBottom: 80,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 24
      },
      '&.bgOverlay': {
        "&:after": {
          pointerEvents: 'none',
          content: "''",
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,.4)',
          bottom: 0,
          top: 0,
          left: 0,
          position: 'absolute',
        },
      },
      "&.bgOverlayVideo": {
        overflow: 'hidden',
      },
      "&.bgOverlayVideo:after": {

        maxHeight: '760px',
        height: '54.25vw !important',
        [theme.breakpoints.down("md")]: {
          height: 'calc(54.25vw - .5rem) !important',
        },
        [theme.breakpoints.down("md")]: {
          height: 'calc(54.25vw - .5rem) !important',
        },
      },
      '&.noOverlay': {
        "&:after": {
          pointerEvents: 'none',
          content: "''",
          width: '100%',
          height: '100%',
          backgroundColor: 'transparent',
          top: 0,
          left: 0,
          position: 'absolute',
        },
        '&:hover': {
          opacity: 1,
        },
      },
      "& .title, & .subtitle": {
        margin: 0,
        color: '#fff',
      },


      "& .banner-sm": {
        minHeight: 350,
        "& .subtitle": {
          fontSize: 24,
          [theme.breakpoints.down("sm")]: { fontSize: 20 },
        },
        "& .title": {
          fontSize: 60,
          fontWeight: 700,
          [theme.breakpoints.down("sm")]: { fontSize: 36 },
        },
      },

      "& .banner-md, & .banner-lg, & .banner-video": {
        "& .subtitle": {
          fontSize: 30,
          [theme.breakpoints.down("md")]: {
            fontSize: 26,
          },
          [theme.breakpoints.down("sm")]: {
            fontSize: 22,
            margin: '.5rem 0',
          },
        },
        "& .title": {
          fontSize: 80,
          fontWeight: 700,
          [theme.breakpoints.down("md")]: {
            fontSize: 60,
          },
          [theme.breakpoints.down("sm")]: {
            fontSize: 32,
          },

        },
      },
      "& .banner-md": {
        minHeight: 800,

      },
      "& .banner-lg": {
        minHeight: 855,

      },
      "& .banner-video": {
        display: 'none',
        [theme.breakpoints.up("md")]: {
          display: 'block',
          height: '52.75vw',
          maxHeight: '760px',
          // minHeight: '760px',
        }
      },
      "& .banner-xl": {
        minHeight: '91vh',
        [theme.breakpoints.down("md")]: { minHeight: '91vh' },
        [theme.breakpoints.down("sm")]: { minHeight: '91vh' },
        "& .subtitle": {
          fontSize: 24,
          [theme.breakpoints.down("md")]: {
            fontSize: 26,
          },
          [theme.breakpoints.down("sm")]: {
            fontSize: 24,
          },
        },
        "& .title": {
          fontSize: 80,
          fontWeight: 700,
          [theme.breakpoints.down("md")]: {
            fontSize: 60,
          },
          [theme.breakpoints.down("sm")]: {
            fontSize: 28,
          },
        },
      },
      "& h3": {
        textTransform: 'none',
      },
    },
    communityImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      marginBottom: '16px',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    bgLogo: {
      "& img": {
        top: '45%',
        left: '50%',
        width: '50vw',
        height: 'auto',
        position: 'absolute',
        zIndex: 10,
        transform: 'translate(-50%, -55%)',
        maxWidth: '560px',
      }
    },
    communityVideo: {
      width: '100%',
      height: 'auto',
      [theme.breakpoints.up("md")]: {
        height: 'auto',
      },
      marginBottom: '16px',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    coverLink: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 20,
    },
    locationInfo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    bannerContent: {
      position: 'absolute',
      top: '50%',
      transform: "translateY(-50%)",
      left: 0,
      width: 'calc(100%)',
      height: 'auto',
      color: 'white',
      zIndex: 10,
      padding: '0 2rem',
      display: 'flex',
      flexDirection: 'column'
    },
    communityTitle: {
      display: 'block',
      borderBottom: 'solid thin white',
      textAlign: 'center',
      fontSize: 18,
    },
    locationDescription: {
      fontSize: 12,
      lineHeight: '14.4px',
    },
    afterBorder: {
      '& span': {
        position: 'relative',
      },
      '& span:after': {
        content: '""',
        width: '90%',
        height: '1px',
        margin: '0 auto',
        bottom: '-1rem',
        left: '50%',
        transform: 'translateX(-50%)',
        position: 'absolute',
      },
    },
    readMoreLink: {
      textDecoration: 'underline',
      color: theme.palette.grey[900],
    },
  }),
);

const Banner = (props) => {
  const router = useRouter();
  let { node, block } = props;
  node = (!node && block) ? block : node;

  let {
    imgAlt, imgTitle, imgWidth, imgHeight, imageOrig, image_styles,
    video, videoHosted, videoHostedEnd, subtitle, ctaLinks, bannerSize, showLogo, title
  } = node;

  bannerSize = (!bannerSize && video?.videoEmbed) ? 'video' : (bannerSize ? bannerSize : 'md');
  let videoEmbed = null;
  if (video?.videoEmbed) {
    videoEmbed = video.videoEmbed;
    if (video.videoEmbed.includes('https://youtu.be')) {
      videoEmbed = video.videoEmbed.replace('https://youtu.be', 'https://youtube.com/embed');
    }
  }
  const classes = useStyles(props);

  let getImageStyle = pickImageStyle({
    imgAlt,
    imgTitle,
    imgWidth,
    imgHeight,
    imageOrig,
    image_styles,
    title
  },
    'max_width_1400', `/images/placeholder/clubhouse.jpg`);
  ({ imgAlt, imgTitle, imgWidth, imgHeight, imageOrig } = getImageStyle);
  function handleVideoEnded(e) {
    const introVideo = document.getElementById('introVideo');
    const endingVideo = document.getElementById('endingVideo');
    introVideo.classList.add('hidden');
    endingVideo.play();
  };
  return (
    <Box displayPrint="none" px={!videoEmbed ? 0 : 0} className={(title !== null && !videoEmbed ? ' bgOverlay ' : ' noOverlay ') + (videoHosted ? 'bgOverlayVideo ' : ' ') + classes.root + (router?.asPath.includes('/about') ? ' about-banner' : '') + (bannerSize === 'lg' ? ' large-banner' : '')}>
      <Box className="ctaWrapper">
        <Box className={`banner banner-${bannerSize}`}>
          {imageOrig && !videoEmbed && !videoHosted &&
            <div className={'imgWrapper'}>
           <Image
              height={imgHeight}
              width={imgWidth}
              className={classes.communityImage}
              src={imageOrig}
              alt={imgAlt || imgTitle || ''}
              title={imgTitle}
              layout="fill"
              priority
            />
            </div>
          }
          {videoEmbed &&
            <iframe frameBorder="0" allow='autoplay' height="100%" width="100%"
              className={`embed-banner ${classes.communityEmbedVideo}`}
              htmlAllow="autoplay; fullscreen" allowFullScreen={true}
              src={`${videoEmbed}`} // ?autoplay=1&loop=1&autopause=0&mute=1&title=0&byline=0&portrait=0
              title="Video playback" />
          }
          {videoHosted && videoHostedEnd &&

            <Hidden xsDown>
              <video onEnded={handleVideoEnded} id="introVideo" className={`video-banner ${classes.communityVideo}`} autoPlay={true} muted={true}>
                <source src={videoHosted} type="video/mp4" />
              </video>
              <video id="endingVideo" className={`video-banner ${classes.communityVideo}`} autoPlay={true} muted={true} loop={true}>
                <source src={videoHostedEnd} type="video/mp4" />
              </video></Hidden>
          }
          {videoHosted && !videoHostedEnd &&
            <Hidden xsDown>

              <video className={`video-banner ${classes.communityVideo}`} autoPlay={true} muted={true} loop={true}>
                <source src={videoHosted} type="video/mp4" />
              </video>
            </Hidden>
          }
          <Box className={`${classes.bannerContent} ctaContent`}>
            {!videoEmbed && !videoHosted &&
              <>
                <Typography key={`subtitle`} align="center" className="subtitle"
                  component="p" variant="h3">
                  {subtitle}
                </Typography>
                <Typography key={`title`} align="center" className="title"
                  component="p" variant="h3">
                  {title}
                </Typography>
                <Box mt={4} className='bannerButtonWrapper'>
                  <Grid container justifyContent="center" spacing={4}>

                    {ctaLinks?.map((bannerButton, index) => (
                      <Grid item key={`banner-links-${index}`}>
                        <Button disableElevation variant="contained" color="primary"
                          href={bannerButton.second}>{parse(bannerButton.first)}</Button>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </>
            }

            {videoEmbed && showLogo &&
              <Hidden xsDown>

                <Box className={classes.bgLogo}>
                <Image width="560" height="400" layout="responsive" sizes="20vw"  src={`/images/bay_logo.png`} alt="Logo"/>
                </Box>
              </Hidden>
            }
            {videoHosted && showLogo &&
              <Hidden xsDown>
                <Box className={classes.bgLogo}>
                  <img src={`/images/bay_logo.png`} alt="Logo" />
                </Box>
              </Hidden>
            }
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Banner;
